<template>
<v-sheet class="main-page-container pt-2">
  <Header @pickObject="pickObject" :selectedObj="this.okrObj[this.selIndex]" :selIndex="selIndex" />
  <div class="page-content d-flex justify-space-between align-top flex-wrap">
    <ThisWeek :selectedObj="this.okrObj[this.selIndex]" />
    <Object @openObjDetailTab="openObjDetailTab" @openKRDetailTab="openKRDetailTab" :selIndex="selIndex" :selObject="selObject" :selObjectId="selObjectId" ref="object" />
    <NextWeek :selectedObj="this.okrObj[this.selIndex]" />
    <Progress />
  </div>
  <CreateProjectDlg @create="createNewItem" ref="createItemDlg" />
  <!-- <v-navigation-drawer :width="isShowObj ? 800 : 0" right permanent fixed>
    <v-row class="d-flex justify-end ma-3">
      <v-btn fab small text @click="closeObjDetailTab">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <OkrDetailComponent v-if="selectedObj" :obj_id="currentObject" :isTab="true" />
  </v-navigation-drawer> -->
  <RightObjectEditor ref="rightObjectEditor" />
  <RightEditor ref="rightEditor" />
</v-sheet>
</template>

<script>

import {
  getUser
} from '@/utils/authUser.service';
import {
  mapGetters,
  mapActions
} from 'vuex';
import { Routes } from '@/constants/routes';
export default {
  name: 'OKRWorkingMethodPage',
  components: {
    CreateProjectDlg: () => import('@/components/common/CreateProjectDlg.vue'),
    RightEditor: () => import('@/components/okrgoal/rightInfo/RightEditor.vue'),
		Header: () => import('@/components/workingMethod/Header.vue'),
		NextWeek: () => import('@/components/workingMethod/NextWeek.vue'),
		Object: () => import('@/components/workingMethod/Object.vue'),
		Progress: () => import('@/components/workingMethod/Progress.vue'),
		ThisWeek: () => import('@/components/workingMethod/ThisWeek.vue'),
		// OkrDetailComponent: () => import('@/components/okrgoal/okrDetail/OkrDetailComponent.vue'),
    RightObjectEditor: () => import('@/components/okrmap/RightObjectEditor.vue'),
  },
  data() {
    return {
      newTask: {},
      isShowObj: false,
      selIndex: null,
			selObject: {},
			selObjectId:null,
    }
  },
  mounted() {
    if(this.okrObj.length==0) {
      //this.$router.push({path: `${Routes.OKR_LIST_PAGE}/10/1`});
    } else {
      const index = this.okrObj.findIndex(ob => ob.ob_owner == this.authUser.id);
      if(index === -1) {
        this.$router.push({path: `${Routes.OKR_LIST_PAGE}/10/1`});
      } else {
        const object = this.okrObj[index];
        this.getDetail(object.ob_id);
        this.selObject = object;
        this.selIndex = index;
        this.selObjectId = object.ob_id;
      }
    }
  },
  computed: {
    ...mapGetters("okr", ["okrObj", "okrDetail"]),
    ...mapGetters('auth', ['authUser'])
  },
  methods: {
    ...mapActions("user", ['getUsers']),
    ...mapActions("okr", ["getOkrsCallback", "getDetail", "getOkrsForAddsort"]),
    ...mapActions('item', ['addItem', 'updateItem']),
    ...mapActions('progress', ['getObjectProgress']),
    getCurrentWeek() {
      let curr = new Date; // get current date
      let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
      let last = first + 6; // last day is the first day + 6

      let firstday = new Date(curr.setDate(first)).toUTCString();
      let lastday = new Date(curr.setDate(last)).toUTCString();
      return {
        startDate: firstday,
        endDate: lastday
      }
    },
    createNewItem(newItem) {
      this.addItem(newItem)
    },
    openObjDetailTab() {
      this.$refs.rightObjectEditor.open(this.okrObj[this.selIndex])
    },
    closeObjDetailTab() {
      if (!this.isShowObj) {

      } else {
        this.isShowObj = false;
      }
    },
    openKRDetailTab(data) {
      this.$refs.rightEditor.open({
        krObj: data,
        obId: this.okrObj[this.selIndex].ob_id,
        obStatus: this.okrObj[this.selIndex].ob_status
      });
    },
    pickObject(e) {
			this.selObjectId = e;
      this.selIndex = this.okrObj.findIndex((o) => o.ob_id == e);
			this.selObject = this.okrObj[this.selIndex];
			this.$refs.object.reRender();
      // this.getObjectProgress({
      //   "pr_parent": e,
      // });
    }
  }
}
</script>

<style scoped>
.main-page-container {
  background-color: rgb(40, 121, 255);
}

.page-content {
  height: calc(90vh);
  background-color: #fff;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  align-items: stretch;
}
</style>
